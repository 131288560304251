<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-select
                v-model="formData.assessType"
                placeholder="评价类型"
                clearable
              >
                <el-option
                  v-for="(item, index) in evaluateTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.assessContent"
                placeholder="评价内容"
                clearable
              >
                <el-option
                  v-for="(item, index) in evaluateContentList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.userId"
                clearable
                placeholder="用户ID"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="datetimerange"
                type="datetimerange"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleSearch"
        >
          搜索
        </el-button>
      </template>
    </BHeader>

    <el-table
      :data="logListInfo.list"
      stripe
    >
      <el-table-column
        prop="memberId"
        label="用户ID"
        min-width="100"
      />
      <el-table-column
        prop="assessType"
        label="评价类型"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ row.assessType | evaluateTypeFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="problemKey"
        label="评价选项"
        min-width="150"
      >
        <template v-slot="{row}">
          {{ (row.problemKey ||[]).join(',') || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        min-width="100"
      />
    </el-table>
    <Pagination
      :total="logListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryLogList"
    />
  </Layout>
</template>

<script>
import { evaluateTypeList, evaluateTypeMap, evaluateContentList } from '@/utils/selectOptions'
import { queryScoreLogList } from '@/api/log'
import { getYesterdaySection } from '@/utils'

export default {
  name: 'ScoreLog',
  filters: {
    evaluateTypeFilter (type) {
      return evaluateTypeMap[type].name || '未知'
    }
  },
  data () {
    return {
      evaluateTypeList,
      evaluateContentList,
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      datetimerange: getYesterdaySection('timestamp'),
      logListInfo: {
        loading: false,
        total: 0,
        list: []
      }
    }
  },
  created () {
    this.queryLogList()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryLogList()
    },
    queryLogList () {
      this.logListInfo.loading = true
      let startTime
      let endTime
      if (this.datetimerange && this.datetimerange.length) {
        startTime = this.datetimerange[0] / 1000
        endTime = this.datetimerange[1] / 1000
      }
      queryScoreLogList({
        ...this.formData,
        startTime,
        endTime
      })
        .then(res => {
          this.logListInfo.list = res.data.list
          this.logListInfo.total = res.data.total
        })
        .finally(() => {
          this.logListInfo.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less">

</style>
